.menuMain {
  background-color: $white !important;
  box-shadow: $box-shadow !important;
  .logo {
    width: 170px;
  }
}
.logoB {
  display: block;
  float: right;
  width: 100%;
  img {
    height: 60px;
    background: #fff;
    padding: 0;
    top: 3px;
    position: relative;
    border-radius: 4px;
  }
}
.logo {
  p {
    margin: 0;
    font-size: 10px;
    color: $theme-font-color-secondary !important;
  }
  img {
    width: 150px;
    vertical-align: bottom;
  }
}
.MuiAppBar-colorPrimary,
.makeStyles-drawer-6 .MuiDrawer-paperAnchorLeft {
  background-color: $theme-color-secondary !important;
  border: 0px !important;
  box-shadow: $box-shadow;
}
.MuiListItemIcon-root {
  color: $theme-font-color-secondary !important;
  display: inline-flex;
  min-width: 56px;
  flex-shrink: 0;
  @include breakpoint(mobile) {
    min-width: 44px !important;
  }
}
.MuiTypography-displayBlock,
.MuiIconButton-root {
  color: $theme-font-color-secondary !important;
}
.makeStyles-drawerClose-8 {
  width: 60px !important;
}

.menuLink {
  .MuiListItem-button {
    padding: 15px 19px;
    text-transform: uppercase;
    .homd {
      margin-right: 18px;
    }
    .MuiSvgIcon-root {
      float: right;
    }
  }
  .submenu {
    background-color: #f5f5f5;
  }
  a {
    display: block;
    color: $theme-font-color-secondary;
    padding: 15px 47px;
    border-radius: inherit;
    padding-left: 10px;
    text-transform: capitalize;
    .MuiSvgIcon-root {
      vertical-align: sub;
      margin-left: 12px;
      margin-right: 15px;
    }
  }
}

// .makeStyles-usernmae-10 {
//   background: transparent !important;
//   box-shadow: none !important;
//   padding: 0px;
//   color: #fff !important;
//   font-size: 18px;
//   border-radius: 0px !important;
// }
header {
  box-shadow: $box-shadow !important;
  &.makeStyles-appBarShift-4,
  &.jss4 {
    @include breakpoint(tablet) {
      width: calc(100% - 220px);
      margin-left: 220px;
    }
    @include breakpoint(mobile) {
      width: 100%;
      margin-left: 0;
    }
  }
  .mobileHamburger {
    display: none;
    @include breakpoint(mobile) {
      display: inline-block;
    }
  }
  .mobileHideDesktopHamburger {
    @include breakpoint(mobile) {
      display: none;
    }
  }
}
