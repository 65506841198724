.sidebarSection {
  .userInfo {
    padding: 20px 20px 20px 75px;
    background: rgb(16, 39, 90);
    text-align: left;
    color: $theme-font-color-primary !important;
    @include breakpoint(tablet) {
      padding: 15px 15px 15px 55px;
    }
    p {
      margin: 0;
      margin-top: 5px;
      font-size: 11px;
      color: #f5f6fa;
    }
    .MuiSvgIcon-root {
      font-size: 41px;
      position: absolute;
      page-break-after: always;
      left: 15px;
      @include breakpoint(tablet) {
        font-size: 30px;
        left: 10px;
      }
    }
  }
  .makeStyles-drawerOpen-6,
  .jss6 {
    @include breakpoint(tablet) {
      width: 220px;
    }

    @include breakpoint(mobile) {
      width: 100%;
    }
  }
  .MuiListItem-root {
    padding: 8px 8px 8px 20px ;
  }

  @include breakpoint(mobile) {
    width: 100%;
    display: none;
  }
  &.show {
    display: block;
    width: 100%;
  }
}
