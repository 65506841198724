.addDatasection {
  .form-btn {
    display: block;
    width: 100%;
    padding-left: 10px;
    margin-bottom: 20px;
    @include breakpoint(mobile) {
      padding-left: 0;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  .formW {
    width: 18%;
    margin: 0px 1%;
    margin-bottom: 25px;
    position: relative;
    .MuiFormLabel-root {
      font-size: 15px;
    }
    @include breakpoint(laptop) {
      width: 30.5%;
    }
    @include breakpoint(tablet) {
      width: 48%;
    }
    @include breakpoint(mobile) {
      width: 100%;
      margin: 0 0 1% 0;
    }
  }
  .MuiFormControl-marginNormal {
    margin-top: 0px;
    margin-bottom: 8px;
  }
  .MuiPaper-root {
    position: absolute;
    top: 48px;
    z-index: 9;
    width: 100%;
    box-shadow: none;
    ul {
      list-style: none;
      padding: 0px;
      margin: 0;
      border-radius: $radius;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      li {
        padding: 10px;
        &:hover {
          background-color: $common-hover;
          cursor: pointer;
        }
      }
    }
    &.alert-msg {
      position: static;
    }
  }
  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    position: absolute;
    bottom: -22px;
  }
}
.clapse {
  .MuiExpansionPanelSummary-content {
    @include breakpoint(mobile) {
      margin: 0;
    }
    .changeBTN {
      display: block;
      transition: 1m;
    }
  }
  .Mui-expanded {
    .changeBTN {
      display: none !important;
      transition: 1m;
    }
  }
}
.MuiButton-containedPrimary {
  color: #fff;
  background-color: #596edc;
}
#query-helper-text {
  position: absolute;
  top: 48px;
}

.formW {
  .autocomplete-list-item {
    font-size: 12px;
    color: #909090;
    border-top: 1px solid #e8eaf5;
  }
  .autocomplete-list-item img {
    max-width: 60px;
    display: block;
    margin-bottom: 5px;
    max-height: 30px;
  }

  .autocomplete-list-item span {
    border: 1px solid #e8eaf5;
    font-size: 10px;
    padding: 3px;
    border-radius: 4px;
    margin-left: 5px;
  }
}

.MuiSelect-select {
  &.MuiSelect-select {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.MuiTableCell-body {
  .MuiIconButton-root {
    color: rgba(0, 0, 0, 0.54) !important;
    padding: 6px !important;
  }
}
.form2 {
  width: 100% !important;
}

// Popup
.MuiDialog-container {
  .MuiDialog-paperWidthSm {
    max-width: 900px;
    position: relative;
    .MuiTypography-h6 {
      color: #3f51b5;
      font-size: 1.5rem;
      padding-right: 50px;
      @include breakpoint(mobile) {
        font-size: 1.2rem;
        line-height: 1.2;
      }
    }
    .MuiDialogActions-root {
      position: absolute;
      right: 0;
      top: 10px;
    }
    .MuiDialogContent-root {
      padding: 16px 24px;
    }
    .MuiButton-containedPrimary {
      margin: 0 10px 0 0;
    }
    .form-btn {
      padding-left: 0;
    }
  }
}
.MuiExpansionPanelSummary-root.Mui-expanded {
  @include breakpoint(mobile) {
    min-height: 48px !important;
  }
}
.MuiExpansionPanelDetails-root {
  @include breakpoint(mobile) {
    padding-top: 0 !important;
  }
}

.refreshIcon {
  position: absolute;
  right: 0;
  bottom: 13px;
  cursor: pointer;
  button.MuiIconButton-root {
    padding: 8px;
  }
  svg {
    color: #10275a;
  }
  &.green {
    button.MuiIconButton-root {
      background-color: #10275a;
    }
    svg {
      color: white;
    }
  }
}
.form-btn.formW
{
  margin-top: 50px;
  margin-bottom: 0;
}