.footer
{
    background-color: $primary-color;
    color:$white;
    padding: 10px  20px;
    font-size: 14px;
    text-align: center;
    display: none;
}
.footerDTA
{
    color:#A3ABBC;
    clear: both;
    position: absolute;
    bottom: 20px;
    font-size: 11px;
    text-align: center;
    width: 85%;
    white-space: break-spaces;
    left: 0;
    right: 0;
    margin: 0px auto;
    transition: 1s;
}
.makeStyles-drawerClose-7
{
    .footerDTA
    {
        display: none;
        transition: 1s;
    }
}