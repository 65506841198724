@import url("https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,600,700,800&display=swap");

$font-stack: Helvetica, sans-serif;
$primaryfontfamily: "Open Sans";
$primary-color: #0e224f;
$theme-color-primary: #0e224f;
$theme-color-secondary: #fff;
$theme-font-color-primary: #fff;
$theme-font-color-secondary: #0e224f;
$theme-font-color-LightOne: #7d87a0;
$white: #ffffff;
$box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
$common-hover: #f5f6fa;
$radius: 4px;
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font: 100% $font-stack;
  color: $primary-color;
  overflow-x: hidden;
}
.headingTXT {
  font-size: 39px;
}
.common_section {
  //margin: 100px 0px 00px;
  min-height: 100vh;
}
.table_Section {
  .MuiToolbar-gutters {
    .material-icons {
      font-size: 25px !important;
    }
    h6 {
      font-size: 20px !important;
    }
  }
  table th:first-child {
    padding-left: 20px !important;
  }
}
main {
  min-height: 100vh;
}
.clapse {
  .MuiIconButton-root {
    color: #000 !important;
  }
  margin-bottom: 30px;
}
// ::-webkit-scrollbar {
//   width: 10px;
//   border-radius: 50%;
// }
// ::-webkit-scrollbar-track {
//   background: #fff;
//   border-radius: 5px;
// }
// ::-webkit-scrollbar-thumb {
//   background: #dee1ed;
//   border-radius: 5px;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background: #7d87a0;
//   border-radius: 5px;
// }

.ToolbarP {
  width: 100%;
  .listContainer {
    width: 100%;
    overflow: auto;
    @include breakpoint(tablet) {
      max-height: 500px;
    }
    thead {
      background: #e8eaf5;
      padding: 5px 13px;
      th {
        background: #e8eaf5;
      }
      .MuiTableCell-root {
        padding: 12px 9px;
        font-size: 12px;
        line-height: 17px;
        font-weight: 600;
        text-transform: uppercase;
        color: $theme-font-color-LightOne;
        border-left: 1px solid rgba(224, 224, 224, 1);
        @include breakpoint(mobile) {
          padding: 5px 8px;
        }
        .MuiSvgIcon-root {
          position: absolute;
          top: 50%;
          right: -5px;
          width: 20px;
          font-size: 43px;
          height: auto;
          transform: translateY(-50%);
          color: green;
        }
      }
    }
    tbody {
      .MuiTableCell-root {
        padding: 12px 0px 12px 9px;
        font-size: 13px;
        border-left: 1px solid rgba(224, 224, 224, 1);
        color: $theme-font-color-LightOne;
        @include breakpoint(mobile) {
          padding: 5px 8px;
        }
      }
    }
  }
  .actionBTN {
    padding: 0px !important;
    .MuiSvgIcon-root {
      font-size: 18px;
    }
    .MuiIconButton-root {
      padding: 0px 0 0 2px !important;
      .MuiIconButton-label {
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.tableP {
  width: 100%;
}
.ToolbarP {
  padding: 20px;
  width: 100%;
  overflow: hidden;
  @include breakpoint(mobile) {
  }
}
.cellW {
  width: 50px !important;
}
.MuiPaper-elevation1 {
  box-shadow: none;
}

.addDatasection {
  .notifyBox {
    .MuiPaper-root {
      position: static;
    }
    textarea {
      height: 60px;
      width: 100%;
      resize: none;
      margin-bottom: 13px;
    }
  }
  .formW  .MuiFormControl-root
  {
    width:100% !important;
  }
  .MuiFormLabel-root {
    font-size: 14px !important;
    text-transform: capitalize;
}
}
.inner_ipo_form
{
  .formW 
  {
    width: 23% !important;
    float: left;
    margin-right: 18px;
    margin-bottom: 20px;
    height: 50px;
    .MuiFormControl-root
    {
      width:100%;
    }
    label {
      text-transform: capitalize;
    }
  }
  .form-btn
  {
    clear: both;
  }
}

.ipolisting
{
  text-align: center;
  td, th
  {
    color:#212121 !important;
  }
  thead .MuiTableCell-root {
    padding: 10px;
    font-size: 13px;
    white-space: pre;
    font-weight: 600;
    border-right: 0 solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-right: 0 solid #f3f3f3 !important;
    // border-bottom: 1px solid #f8f8f8 !important;
    border-left: 0px solid #f8f8f8 !important;
}
  tbody .MuiTableCell-root {
    padding: 10px;
    font-size: 13px;
    white-space: pre;
    // font-weight: 600;
    border-right: 0 solid #f3f3f3 !important;
    border-bottom: 1px solid #f8f8f8 !important;
    border-left: 1px solid #f8f8f8 !important;
}
  .symble-logo
  {
    text-align: center;
    font-weight: 600;
    span
    {
      width: 20px;
      border-radius: 50%;
      display: inline-block;
      overflow: hidden;
      border: 1px dashed #ddd;
      height: 20px;
      padding: 2px;
      margin: 0px !important;
      text-align: center;
    img 
    {
      width:100%;
      border-radius: 50%;
    }
    }
  }
  .symble
  {
    text-align: center;
    display: block !important;/* or inline-block */
    text-overflow: ellipsis !important;
    word-wrap: break-word !important;
    overflow: hidden !important;
    width:80px !important;
    border: 0px !important;
  }
  .symbleN
  {
    text-align: center;
    display: block !important;/* or inline-block */
    text-overflow: ellipsis !important;
    word-wrap: break-word !important;
    overflow: hidden !important;
    width:80px !important;
    border: 0px !important;
  }
  .symbleI
  {
    text-align: center;
    display: block !important;/* or inline-block */
    text-overflow: ellipsis !important;
    word-wrap: break-word !important;
    overflow: hidden !important;
    width:50px !important;
    border: 0px !important;
  }
  .CnmA
  {
    text-align: center;
    display: block;/* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    width:90px;
  }
  button
  {
    display:inline-block;
    // font-size: 25px;
    .MuiSvgIcon-fontSizeSmall {
      font-size: 18px;
  }
  }
  a 
  {
    color:#4CAF50;
    text-decoration: none;
  }
}

.custom-image-upload {
  position: relative;
  input[type=file] {
    cursor: pointer;
    width: 180px;
    height: 34px;
    overflow: hidden;
  }
  
  input[type=file]:before {
    width: 158px;
    height: 32px;
    font-size: 16px;
    line-height: 32px;
    content: 'Select your file';
    display: inline-block;
    background: white;
    border: 1px dashed #000;
    padding: 0 10px;
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
  }
  
  input[type=file]::-webkit-file-upload-button {
    visibility: hidden;
  }
  button
  {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.dNone
{
  display:none;
}
.fixT
{
  pointer-events: none;
}
.basic-popupbtn
{
  position:relative !important;
  top: 0px !important;
  justify-content: center  !important;
}
.quistion_Popup
{
  .MuiDialog-paperWidthSm
  {
    width: 50%;
  }
  .MuiFormControl-root
  {
    width: 100%;
  }
}
.notification_Popup
{
  .MuiDialog-paperWidthSm
  {
    width: auto;
  }
  .MuiDialogContent-root
  {
    padding:0px !important;
  }
}
.formW.about_Text
{
  width: 100% !important;
  height: auto;
  margin-top: 20px;
  textarea
  {
    width: 100%;
    min-height: 100px;
    max-width: 100% !important;
  }
  label {
    margin-bottom: 7px;
  }
}
.positin_p
{
  .des 
  {
    width: 46% !important;
    overflow:hidden;
  }
  .name 
  {
    width: 54% !important;
  }
}
.commonInput
{
  margin-bottom:20px
}
.inUpload
{
  width:100%;
  .formW
  {
    width:30% !important;
    float:left;
  }
}

.ToolbarP {
  width: 100%;
  padding: 20px;
  overflow: auto;
  padding-top: 65px!important;
}
.mainCt {
  background: #f2f3f7;
  border-radius: 20px;
  height: 87vh;
  overflow: hidden;
  padding-top: 20px;
}
.innerprofilesection {
  overflow: scroll;
  height: 86vh;
  padding: 0 20px 20px;
}
.MuiDrawer-paperAnchorDockedLeft
{
  border-right:0px !important;
}
header.MuiAppBar-colorPrimary {
  box-shadow: none !important;
}

.newUiupadte
{
  background: #fff;
    padding: 5px;
    border-radius: 10px !important;
    -webkit-box-shadow: 0px 3px 15px #00000029 !important;
    box-shadow: 0px 3px 15px #00000029 !important;
}
.searchUpdatesectin
{
  background: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
  // height: 40px;
padding:0px 10px;
  -webkit-box-shadow: 0px 3px 15px #00000029;
  box-shadow: 0px 3px 15px #00000029;
.csvDownloadBtn
{
  float: right;
}
}
.tableP
{
  box-shadow: none !important;

}
body 
{
  overflow: hidden;
}
.intop
{
.MuiInputLabel-animated {
  transform: translate(0, 10px) scale(0.75);
  transform-origin: top left;
}
.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}
}


.custom-image-upload input[type=file]
{
  width: 100%;
}
.custom-image-upload button {
  position: absolute;
  bottom: 5px;
  right: 9px;
  padding: 0;
}
.custom-image-upload input[type=file]:before
{
  width: 90%;
}



@media (max-width: 767px) {
  .custom-image-upload input[type=file]
  {
    width: 100%;
  }
  .custom-image-upload button {
    position: absolute;
    bottom: 5px;
    right: 9px;
    padding: 0;
  }
  .custom-image-upload input[type=file]:before
  {
    width:96%;
  }


  .inner_ipo_form .formW {
    width: 100% !important;
    float: left;
    margin-right: 18px;
    margin-bottom: 8px;
    height: 50px;
}
.ToolbarP
{
  padding:10px;
  padding-top:57px !important;
}
.innerprofilesection, .mainCt
{
  height:100vh;
}
.ToolbarP .listContainer {
  max-height: inherit;
}
.innerprofilesection
{
  padding:10px;
}
body {
  overflow: auto;
}
.formW.about_Text {
  width: 100% !important;
  height: auto;
  margin-top: 20px;
}
}


.dpinformation {
  width: 100%;
  padding: 20px;
  .divider {
    margin-bottom: 0;
  }
  @include breakpoint(tablet) {
    padding: 10px;
  }
}
.csvupload
{
  .divider 
  {
    padding: 20px 20px !important;
  h2 {
    padding-left: 0% !important;
    margin-top: 0px;
  }
  }
  h3 
  {
    color: #f44336;padding-left: 0% !important;margin-bottom: 28px !important;
    font-weight: 500;
  }
.hide
{
  margin-bottom: 20px;
}
.btn {
  border: 1px solid gray;
  color: gray;
  background-color: #11275a;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
}
 .card-user
 {
  cursor: pointer;
  width: auto;
  display: inline-block;
  max-width: 300px;
 } 
}
.content {
  position: relative;
  overflow: hidden;
  display: inline-block;
}



.content input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.uploadPt
{
  padding: 0 0px 80px;
}
@media (min-width: 768px) and (max-width: 992px){

  .inner_ipo_form .formW {
    width: 100% !important;
    float: left;
    margin-right: 18px;
    margin-bottom: 8px;
    height: 50px;
}
.formW.about_Text {
  width: 100% !important;
  height: auto;
  margin-top: 20px;
}
.custom-image-upload input[type=file]
{
  width: 100%;
}
.custom-image-upload button {
  position: absolute;
  bottom: 5px;
  right: 9px;
  padding: 0;
}
.custom-image-upload input[type=file]:before
{
  width: 95%;
}
}
@media (min-width: 993px) and (max-width: 1300px){

  .inner_ipo_form .formW {
    margin-right: 10px;
    margin-bottom: 8px;
    height: 50px;
    width: 23% !important;
}
.custom-image-upload input[type=file]:before
{
  width: 92%;
}
.formW.about_Text {
  width: 100% !important;
  height: auto;
  margin-top: 20px;
}
.inner_ipo_form  .logoUp
{
  width: 45% !important;
  margin-top: 18px;
}
.custom-image-upload input[type=file]
{
  width: 100%;
}
.custom-image-upload button {
  position: absolute;
  bottom: 5px;
  right: 9px;
  padding: 0;
}
}
.ttr
{
  margin-top:35px !important;
}
.faqListC
{

  .faqList
  {
    margin-bottom: 20px;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    
  }
  .qNum
  {
    margin-bottom: 5px;
    position: relative;
    padding-right: 50px;
    div
    {
      position:absolute;
      right: -15px;
      top:-8px
    }
    button
    {
      min-width:auto;
    }
  }
  .aNum
  {
    position: relative;
    padding-left:45px;
    ul, p
    {
      margin:0px;
    }
    .ansD
    {
      position: absolute;
      top:0;
      left:0;
    }
  }
}
.wrapper{
  border: 1px solid grey;
}

.editor{
  background-color: lavender;
}
.faq-btn
{
  padding: 7px 10px !important;
      line-height: inherit !important;
      min-width: auto !important;
      font-size: 10px !important;
      // text-transform: capitalize !important;
}
.dNoo
{
  display:none;
}
.innerF
{
  display:inline-block;
}
// .MuiDialog-container .MuiDialog-paperWidthSm .MuiDialogActions-root
//   {
//     position:relative !important;
//   }
.tox-notifications-container
{
  display:none !important;
}
.noneS 
{
  display:none !important;
.message-box
{
  display:none !important;
}
}
.dNoneP
{
  display:none !important;  
}
.faq-l
{
  padding-left:0px !important;
  padding-top:0px !important;
}
.visually-hidden, .tox-statusbar__branding
{
  display: none !important;
}
.btnMar
{
  margin-top:50px
}
.status-N
{
  display: none;
}
.table_bc
{
  margin-bottom: 20px;
  table
  {
    border:1px solid #ddd;
    width:100%;
    td
    {
      border:1px solid #ddd;
      padding:5px;
    }
  }
}